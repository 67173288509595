var last_location_call_i = 1;
var last_call_map_stamp = 0;
var last_call_map_str = '';

class CustomAutoCompleteMaps {

		el;

		constructor(search_input_el) {
				let self = this;
				$(search_input_el).on('keyup focus click', function(){
						self.getSuggestion(search_input_el);
				});
				this.dLocationInput = $(search_input_el)
						.after('<div class="loc-dropdown d-none"></div>')
						.after(`<span class="maps-input-loader" style="display: none"><img data-src="${HOST}/assets/images/md-loader.gif" class="lazyload"></span>`);
				this.dDropdown = this.dLocationInput.siblings('.loc-dropdown');
				this.data = '';
				this.el = search_input_el;
				$(window).on('click scroll resize', function() {
						$('.loc-dropdown').addClass('d-none');
				});
				$('*').scroll(function() {
						$('.loc-dropdown').addClass('d-none');
				});
				$(this.dDropdown).click(function(event){
						event.stopPropagation();
				});
				$(this.dLocationInput).attr('autocomplete', 'off');

		}

		getDomElement() {
				return this.el;
		}

		static addListener(CustomAutoCompleteMapsObject, event, callback) {
				const el = CustomAutoCompleteMapsObject.getDomElement();
				el.addEventListener(event, callback);
		}

		setMapsAutoDropdownPosition(calc_el, set_el){
				$(set_el).css('right', (parseInt($(calc_el).css("marginRight").replace('px', ''))+parseInt(calc_el.parent().css('padding-right').replace('px', ''))));
				if($(calc_el).siblings('button').length){
						$(set_el).css('right', $(set_el).css('right', ($(calc_el).siblings('button').outerWidth()+parseInt($(calc_el).css("marginRight").replace('px', ''))+3.5) ) );
						$(calc_el).siblings('.maps-input-loader').css('right', ($(calc_el).siblings('button').outerWidth()+parseInt($(calc_el).css("marginRight").replace('px', ''))-16));
				}
				parseInt($(calc_el).css("marginRight").replace('px', ''))
				$(set_el).css('top', ($(calc_el).outerHeight()+1));
				$(set_el).width($(calc_el).innerWidth());
				if($(calc_el).siblings('.input-group-append').length){
						$(set_el).css('right', $(calc_el).siblings('.input-group-append').outerWidth());
				}


				// $(set_el).css({
				// 		position: "absolute",
				// 		top: $(calc_el).top + "px",
				// 		left: $(calc_el).left + "px"
				// });
				// $(set_el).css('top', calc_el.offset().top + calc_el.innerHeight() + 2 - $(window).scrollTop());
		}

		getSuggestion(context) {
				if(!context.value){
						last_call_map_str = '';
						return false;
				}

				/*  send place api call minimum 3 characters */
				if(context.value.length <= 2 ) return false;

				if( last_call_map_str === context.value && (Date.now() - last_call_map_stamp) < 1000 ){
						return false;
				}
				last_call_map_str = context.value;
				last_call_map_stamp = Date.now();
				this.dDropdown.removeClass('d-none');
				this.setMapsAutoDropdownPosition(this.dLocationInput, this.dDropdown);
				last_location_call_i++;
				this.dDropdown.html(`<span class="maps-dropdown-loader"><img src="${HOST}/assets/images/md-loader.gif"></span>`);
				fetch(`${HOST}/common/server/apis/getPlacesSuggestion.php?input=${context.value}&last_call_i=${last_location_call_i}&user_ip=${IP_ADDRESS}`)
						.then(response => response.json())
						.then(data => {
								this.dDropdown.removeClass('d-none');
								let html = ``;
								if(last_location_call_i === parseInt(data.resp_for_i)){
										if (typeof data === "object" && data && "message" in data) {
												html = ``;
												this.dDropdown.addClass('d-none');
										} else {
												data = data.data;
												if (data && data.length > 0) {
														data.forEach((obj, key) => {
																html += `<button class="dropdown-item px-3 c-auto-comp-item rounded-0" data-place-id="${obj.place_id}">
																<i class="fa fa-map-marker text-danger"></i><span>${obj.description}</span>
														</button>`;
														});
														html += `<div class="dropdown-item c-maps-g-mark py-0">
													<img alt="" class="img-fluid float-right" style="max-width: 105px;" src="${HOST}/common/images/powered_by_google_on_white_hdpi.png" >
												</div>`;
												} else {
														if(DEBUG) console.log('No data Found.');
												}
										}
										if(!html){
												html = `<div class="c-maps-no-res">No results found</div>`;
										}
										this.setMapsAutoDropdownPosition(this.dLocationInput, this.dDropdown);
										this.dDropdown.html(html);
										let self = this;
										this.dDropdown.children().off().on('click', function (){
												$(self.el).next('.maps-input-loader').show();
												self.setOptionToAct(this);
										});
								}
						})
						.catch(error => console.dir(error));
		}

		setOptionToAct(context) {
				event.preventDefault();
				let self = this;
				$(this.dLocationInput).val($(context).text().trim());
				fetch(`${HOST}/common/server/apis/getPlacesInfo.php?place_id=${$(context).attr('data-place-id')}&user_ip=${IP_ADDRESS}`)
						.then(response => response.json())
						.then(data => {
								if (typeof data === "object" && data) {
										self.data = data;
										self.data.geometry.location.latOriginal = data.geometry.location.lat;
										self.data.geometry.location.lngOriginal = data.geometry.location.lng;
										self.data.geometry.location.lat = self.customMapsLat.bind(data);
										self.data.geometry.location.lng = self.customMapsLng.bind(data);
										$(context).parent().addClass('d-none');
										// console.log('calling...event dispatch');
										self.getDomElement().dispatchEvent(new CustomEvent('place_changed'));
										$(self.el).next('.maps-input-loader').hide();
								} else {
										console.dir(data);
								}
						})
						.catch(error => console.dir(error));
		}

		getPlace(){
				return this.data;
		}

		customMapsLat = function (){
				return this.geometry.location.latOriginal;
		}

		customMapsLng = function (){
				return this.geometry.location.lngOriginal;
		}

}
