/**
 * Created by Azhar on 20-11-2017.
 */

$(function () {
	$('#shortlist-user-btn').on('click', function (e) {
		e.preventDefault();
		$('#shortlist-rec-btn').removeClass('d-none');
		$('#shortlisted-schools-list').removeClass('d-none');
		$('#shortlist-user-btn').addClass('d-none');
		$('#recommended-schools-list').addClass('d-none');
		$('#shortlist-title').html(`My Shortlist`);
	});

	$('#shortlist-rec-btn').on('click', function (e) {
		e.preventDefault();
		$('#shortlist-rec-btn').addClass('d-none');
		$('#shortlisted-schools-list').addClass('d-none');
		$('#shortlist-user-btn').removeClass('d-none');
		$('#recommended-schools-list').removeClass('d-none');
		$('#shortlist-title').html(`Recommended Schools`);
	});
});

function loadWishlist() {
	if (!user) return;
	let _self = this;
	debugger;
	$.get(API_BASE_URL + 'wishlists')
		.always(function (res) {

			let response = res.responseJSON || res;

			if (response.code === 'OK') {
				onChangePreferredModalHomepage(response);
				let wishlists = response.data;
				if (!Array.isArray(wishlists)) {

					wishlistHTML = '';
					displayWishlist(wishlists);
					return;
				}
				wishlists.map(function (item) {
					item.school_url = '/schools/redirect-' + item.code;
					return item;
				});
				displayWishlist(wishlists);

			} else {

			}

		});
}

function displayWishlist(wishlists) {
	let shortlistResponseWrapper = $('#shortlisted-schools-list');
	let recommendedResponseWrapper = $('#recommended-schools-list');
	shortlistResponseWrapper.empty();
	recommendedResponseWrapper.empty();
	UpdateShortlistCount(wishlists.length);
	if (Object.keys(wishlists).length === 0) {
		const EmptyWishlistHTML = `
                    <div class="empty-shortlist-block text-center empty-aside-block">                       
                        <h2 class="empty-short">Empty Shortlists</h2>
                        <p class="empty-short-tex">You have no schools in your shortlists. Start adding!</p>
                    </div>`;
		shortlistResponseWrapper.addClass('d-flex align-items-center').append(EmptyWishlistHTML);
		$('#shortlist').find('a').html('<i class="fa fa-heart-o wishlist-heart"></i> (0)');
		return;
	} else {
		shortlistResponseWrapper.removeClass('d-flex align-items-center')
	}

	// Assume you have user.id available
	const userIdWishlist = user.id; // Replace with actual user id variable
	console.log(wishlists);
	let userShortCount = 0;
	let userRecCount = 0;
	wishlists.forEach((wishlist) => {
		let from = {
			lat: latitude ?? '',
			lng: longitude ?? ''
		};
		let to = {
			lat: wishlist.slat,
			lng: wishlist.slon,
		};
		let distance = calculateDistance(from, to);
		const school_url = window.location.origin + '/schools/redirect-' + wishlist.code + '/?utm_source=shortlist';

		if (wishlist.shortlist_by === userIdWishlist) {
			userShortCount++;
			//const admissionURL = window.location.origin + '/schools/redirect-' + wishlist.code + '/admission';
			const wishlistHTML = `
            <div class="package-grid-shortlist position-relative aside-school-card" data-school="${wishlist.school}">
                <div class="aside-school-image">
                    <a target="_blank" 
                       class="d-block w-100 h-100 shortlist-school-image-wrapper aside-school-image-wrapper" 
                       href="${school_url}">
                        <img data-src="${wishlist.coverpic}"
                             class="aside-school-image shortlisted-image w-100 h-100 lazyload"
                             alt="${wishlist.name}">
                    </a>
                    <span class="d-inline-block distance">
                        ${distance_condition(distance)}
                    </span>
                    <div class="d-none">
                        ${(wishlist.shortlist_by_role === 'counsellor') ? `<span class="counsellor-text-popup">Counsellor</span>` : ``}
                    </div>
                </div>
                <div class="shortlisted-content aside-school-content">
                    <div class="shortlist-school-name aside-school-primary-data">
                        <h3 class="school-name mt-0">
                            <a href="${school_url}">${wishlist.name.toLowerCase()}</a>
                        </h3>
                        <span class="school-address d-flex">
                            <i class="fa fa-map-marker pr-2"></i>
                            ${wishlist.address1 ? wishlist.address1.toLowerCase() : ''}
                        </span>
                        <div class="info-tags">
                            <span class="tags-list ${wishlist.typeid === 2 ? 'hidden' : ''}"><span>Board</span>${get_board(wishlist)}</span>
                            <span class="tags-list">
                                <span>${parseInt(wishlist.typeid) === 2 ? 'Monthly' : 'Annual'} Fees</span>
                                <span>${get_fee_with_unit(wishlist)}</span>
                            </span>
                            <span class="tags-list ${!wishlist.admission_start_date ? 'hidden' : ''}"> 
                                <span>Admission Date</span>
                                <span>${wishlist.admission_start_date}</span>
                            </span>
                        </div>
                    </div>
                    <div class="school-comment-popup ${!wishlist.comment ? 'd-none' : ''}">${wishlist.comment ? wishlist.comment : ''}</div>
                    ${(wishlist.vad === 1) ?
				`<div class="review-text questionSlickPopup visit-school-wrapper">
                            <div class="review-text-line visit-school-container">                                       
                                <span class="question-text">Do you wish to visit this school?</span>
                                <div class="answer-block">
                                    <span class="true-answer ${wishlist.question1 === 1 ? 'active' : ''}" data-question="question1">Yes</span>
                                    <span class="false-answer ${wishlist.question1 === 0 ? 'active' : ''}" data-question="question1">No</span>
                                </div>
                            </div>
                        </div>
                        <div class="datetimeBlockPopup appointment-wrapper position-relative" ${!wishlist.question1 ? 'style="display:none"' : ''}>
                            <span class="appointment-message">Please select visit date & time</span>
                            <input type="text" name="dateTimesPopup" value="${getDateFormatShortlist(wishlist.srm_follow_up_At)}" class="form-control dateTimePopup fixed-appointment" />
                        </div>` : ``}
                    		<div class="school-feedback-wrapper">
                        <div class="emoji-group feedback-controls-wrapper d-flex">
                            <button class="emo-block short-like-block-popup ${wishlist.like === 1 ? 'active' : ''}" data-like-val="1">
                                <i class="fa fa-thumbs-up"></i><span> Interested</span></button>
                            <button class="emo-block short-dislike-block-popup ${wishlist.like === 0 ? 'active' : ''}" data-like-val="0">
                                <i class="fa fa-thumbs-down"></i><span> Not Interested</span></button>                                
                            <a href="tel:${wishlist.counsellor_phone == null ? wishlist.phone1 : wishlist.counsellor_phone}" class="text-unset">
                                <i class="fa fa-phone"></i><span> Call</span></a>
                        </div>
                    </div>
                </div>
                <span class="d-inline-block remove-shortlist">Remove</span>
            </div>`;

			shortlistResponseWrapper.append(wishlistHTML);
		} else {
			userRecCount++;
			recommendedResponseWrapper.append(`
            <div class="package-grid-shortlist position-relative aside-school-card aside-rec-school-card" data-school="${wishlist.school}">
               
                <div class="shortlisted-content aside-school-content rec-aside-school-content">
                    <div class="shortlist-school-name aside-school-primary-data">
                        <h3 class="school-name mt-0">
                            <a href="${school_url}">${wishlist.name.toLowerCase()}</a>
                        </h3>
                        <span class="school-address d-flex">
                            <i class="fa fa-map-marker pr-2"></i>
                            ${wishlist.address1 ? wishlist.address1.toLowerCase() : ''}
                        </span>
                        <div class="info-tags">
                            <span class="tags-list ${wishlist.typeid === 2 ? 'hidden' : ''}"><span>Board</span>${get_board(wishlist)}</span>
                            <span class="tags-list">
                                <span>${parseInt(wishlist.typeid) === 2 ? 'Monthly' : 'Annual'} Fees</span>
                                <span>${get_fee_with_unit(wishlist)}</span>
                            </span>
                            <span class="tags-list ${!wishlist.admission_start_date ? 'hidden' : ''}"> 
                                <span>Admission Date</span>
                                <span>${wishlist.admission_start_date}</span>
                            </span>
                        </div>
                    </div>
                    <div class="school-comment-popup ${!wishlist.comment ? 'd-none' : ''}">${wishlist.comment ? wishlist.comment : ''}</div>
            
                    <div class="school-feedback-wrapper rec-feedback-wrap">
                        <div class="emoji-group feedback-controls-wrapper d-flex">
                            <button class="emo-block short-like-block-popup ${wishlist.like === 1 ? 'active' : ''}" data-like-val="1">
                                <i class="fa fa-thumbs-up"></i></button>
                            <button class="emo-block short-dislike-block-popup ${wishlist.like === 0 ? 'active' : ''}" data-like-val="0">
                                <i class="fa fa-thumbs-down"></i></button>                                
                            <a href="tel:${wishlist.counsellor_phone == null ? wishlist.phone1 : wishlist.counsellor_phone}" class="text-unset">
                                <i class="fa fa-phone"></i></a>
                        </div>
                    </div>
                </div>
                <span class="d-inline-block rec-add-shortlist">Add to Shortlist</span>
            </div>`);
		}
	});

	$('#shortlist-user-count').html(`${userShortCount}`);
	$('#shortlist-rec-count').html(`${userRecCount}`);

	if (wishlists.length > 0) {

		//shortlistPopupQuestionSlick(); // Initialize Slick Question
		InitShortlistQuestionsPopup(); // shortlist question click bind Yes or no state changes
		likeDislikeInitPopup(); // like dislike question click bind
		DateRangeInitPopup(); // Date Range Initialize with Date time edit bution
		ToggleReadMore(); // Read More Read Less comment

		$('#shortlisted-schools-list .remove-shortlist').on('click', function (e) {
			let wishlist_el = $(this).closest('.package-grid-shortlist');
			const school_id = $(wishlist_el).attr('data-school');
			removeWishlist(school_id, function (success) {

				if (success) loadWishlist();
			});
		});
		$('#recommended-schools-list .rec-add-shortlist').on('click', function (e) {
			let wishlist_el = $(this).closest('.package-grid-shortlist');
			const school_id = $(wishlist_el).attr('data-school');

			switchToWishlist(school_id , function (success) {

				if (success) loadWishlist();
			})

		});

		// show count with wishlist menu
		const wishlist_count = wishlists.length;
		$('#shortlist').find('a').html('<i class="fa fa-heart-o wishlist-heart"></i> (' + wishlist_count + ')');
		$('#CheckShortlistFixedButton').find('.count-shortlists').html(wishlist_count);
	}


}

function displayHeight160(condition) {
	return (condition == 1) ? 'height_100' : 'height_140';
}

function displayIf(condition) {
	if (condition) return '';
	else return 'hidden';
}

function addWishlist(school_id, callback) {
	if (!user) {
		$('#loginModal').modal('show');
		return;
	}
	$.ajax({
		url: API_BASE_URL + 'wishlists',
		method: 'POST',
		data: {school: school_id},
		success: function (result) {
			if (callback)
				(result.code === 'CREATED' || result.code === 'OK') ? callback(true) : callback(false);
			gtag('event', {
				event_category: 'Wishlist',
				event_action: 'Wishlist Added',
				event_label: 'Wishlist Added Success'
			});

		},
		error: function (request, msg, error) {
			// console.log('wishlist err', request,msg,error);
			if (callback) callback(false);
		}
	});
}


function getWishlistBySchool(school_id, callback) {
	if (!user) return;
	$.ajax({
		url: API_BASE_URL + 'wishlists/' + school_id,
		method: 'GET',
		success: function (response) {
			if (callback)
				(response.code === 'OK' && response.data && response.data.status)
					? callback(response.data.status) : callback(false);

		},
		error: function (request, msg, error) {
			// console.log('wishlist err', request,msg,error);
			if (callback) callback(false);
		}
	});
}

function removeWishlist(school_id, callback) {
	if (!user) $('#loginModal').modal('show');
	$.ajax({
		url: API_BASE_URL + 'wishlists' + '?school=' + school_id,
		method: 'DELETE',
		success: function (result) {
			if (callback) result.code === 'OK' ? callback(true) : callback(false);
			gtag('event', {
				event_category: 'Wishlist',
				event_action: 'Wishlist Removed',
				event_label: 'Wishlist Removed Success'
			});


		},
		error: function (request, msg, error) {
			// console.log('wishlist err', request,msg,error);
			if (callback) callback(false);
		}
	});
}

function switchToWishlist(school_id , callback) {
	if (!user) $('#loginModal').modal('show');

	/*TODO : WRITE a Ajax call to update ShortlistBY*/


	$.ajax({
		url: `${HOST}/home/wishlist-new/server/switch-to-self-wishlist.php`,
		method: "POST",
		data: {school_id: school_id}
	}).done(function (res) {
		if (callback) res ? callback(true) : callback(false);
	}).fail(function (err) {
		$.toaster({
			priority: 'danger',
			title: 'Error',
			message: 'Something Went Wrong, Please try again',
			settings: {timeout: 4000}
		});
		if (callback) callback(false);
		return null;
	});
}

function toggleDetailsPageWishlist() {
	let el = $('.btn-wishlist');
	const school_id = $(el[0]).attr('data-school_id');

	getWishlistBySchool(school_id, function (status) {
		if (status) {
			$(el).html('<i class="fa fa-heart"></i>');
			$(el).addClass('wishlisted');
		}
		// Default it status false;
	});
	$(el).on('click', function () {
		const wishlisted = $(el).hasClass('wishlisted');
		if (!wishlisted) {
			addWishlist(school_id, function (success) {
				if (success) {
					$(el).html('<i class="fa fa-heart"></i>');
					$(el).addClass('wishlisted');
					gtag('event', {
						event_category: 'Wishlist',
						event_action: 'Wishlist Added',
						event_label: 'Wishlist Added Success'
					});
					loadWishlist();

				}
			})
		} else {
			removeWishlist(school_id, function (success) {
				if (success) {
					$(el).html('<i class="fa fa-heart-o"></i>');
					$(el).removeClass('wishlisted');
					gtag('event', {
						event_category: 'Wishlist',
						event_action: 'Wishlist Removed',
						event_label: 'Wishlist Removed Success'
					});

					loadWishlist();

				}
			})
		}
	})
}

const get_fee_with_unit = (school) => {

	let {fees = null, yearly_fees2 = null} = school;
	const {typeid: school_type_id = "1"} = school;
	let feesText = fees;

	if (parseInt(school_type_id) === 2 && !!fees) {
		fees = Math.ceil(fees / 12);
		feesText = parseFloat(parseInt(fees).toFixed(2));
	}

	if ([3, 4].includes(parseInt(school_type_id)) && !!yearly_fees2) feesText = yearly_fees2;

	feesText = indianPriceFormat(feesText);
	if (([3, 4].includes(parseInt(school_type_id)) && !yearly_fees2)) feesText = null;
	return feesText ? `₹${feesText}` : "N/A";

};

const get_board = (school) => {
	if (school.board1 && school.board2) {
		return `<span>` + school.board1 + ", " + school.board2 + `</span>`;
	} else if (school.board1 && school.board2 == null) {
		return `<span>` + school.board1 + `</span>`;
	} else if (school.board1 == null && school.board2) {
		return `<span>` + school.board2 + `</span>`;
	} else {
		return `<span> N/A </span>`;
	}
};

function UpdateShortlistCount(WishlistCount) {
	if (WishlistCount == 0 || WishlistCount == undefined) {
		$('.count-shortlists').hide();
	} else {
		$('.count-shortlists').show();
		$('.count-shortlists').text(WishlistCount);
	}
}

function onChangePreferredModalHomepage(jsonData) {
	if (!pageType) return;
	if (pageType === 'HOMEPAGE') {
		updateUserLocationFromPreferredModal();
		shortlistedOnLoadHomePage(jsonData);
	}
}


function InitShortlistQuestionsPopup() {

	$('#shortlisted-schools-list .true-answer').click(function () {
		let schoolVisitResponseBtn = $(this);
		if (schoolVisitResponseBtn.hasClass('active')) return;

		schoolVisitResponseBtn.addClass('active').next().removeClass('active');
		schoolVisitResponseBtn.parent().find('.black-tick').hide(); // hide tick in button No
		schoolVisitResponseBtn.find('.white-tick').show(); // show tick in button Yes
		let school = schoolVisitResponseBtn.closest('.package-grid-shortlist').attr('data-school');
		let question = schoolVisitResponseBtn.closest('.answer-block').find('.true-answer').attr('data-question');
		if (question == 'question1') {
			schoolVisitResponseBtn.closest('.package-grid-shortlist').find('.datetimeBlockPopup').show();
		}
		let questionValue = 1;
		updateQuestionAjaxPopup(school, question, questionValue);
		if (question != 'question3') { // Do not slider after Question 3
			schoolVisitResponseBtn.parent().parent().parent().parent().parent().find('.slick-next').trigger('click');
		}
	});

	$('#shortlisted-schools-list .false-answer').click(function () {
		let schoolVisitResponseBtn = $(this);

		if (schoolVisitResponseBtn.hasClass('active')) return;

		schoolVisitResponseBtn.addClass('active').prev().removeClass('active');
		schoolVisitResponseBtn.parent().find('.white-tick').hide(); // show tick in button Yes
		schoolVisitResponseBtn.find('.black-tick').show(); // hide tick in button No
		let school = schoolVisitResponseBtn.closest('.package-grid-shortlist').attr('data-school');
		let question = schoolVisitResponseBtn.closest('.answer-block').find('.true-answer').attr('data-question');
		if (question == 'question1') {
			schoolVisitResponseBtn.closest('.package-grid-shortlist').find('.datetimeBlockPopup').hide();
		}
		let questionValue = 0;
		updateQuestionAjaxPopup(school, question, questionValue);
		if (question != 'question3') { // Do not slide after Question 3
			schoolVisitResponseBtn.parent().parent().parent().parent().parent().find('.slick-next').trigger('click');
		}
	});
}

function updateQuestionAjaxPopup(schoolId, question, value) {

	$.ajax({
		type: "GET",
		url: HOST + "/home/wishlist-new/server/short-question-update.php",
		data: {school: schoolId, question: question, value: value},
		success: function (data) {
			if (DEBUG) console.log(data);
		},
		error: function (data) {
			if (DEBUG) console.log(data);
		}
	})
}

function likeDislikeInitPopup() {
	$('.short-like-block-popup,.short-dislike-block-popup').on('click', function () {
		let _self = this;
		let school = $(this).closest('.package-grid-shortlist').attr('data-school');
		let likeVal = $(this).attr('data-like-val');
		likeDislikeTextChangesPopup(likeVal, _self);
		updateLikeShortlistAjaxPopup(school, likeVal);
	})
}

function likeDislikeTextChangesPopup(val, elem) {
	if (val === '1') {
		$(elem).parent().find('.short-dislike-block-popup').removeClass('active');
		$(elem).addClass('active');

	} else {
		$(elem).parent().find('.short-like-block-popup').removeClass('active');
		$(elem).addClass('active');

	}
}

function updateLikeShortlistAjaxPopup(schoolId, likeVal) {
	$.ajax({
		type: "GET",
		url: HOST + "/home/wishlist-new/server/update-short-like-status.php",
		data: {school: schoolId, like: likeVal},
		success: function (data) {
			if (DEBUG) console.log(data);
		},
		error: function (data) {
			if (DEBUG) console.log(data);
		}
	})
}

function shortlistPopupQuestionSlick() {

	setTimeout(function () {
		$('.questionSlickPopup').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			adaptiveHeight: true,
			infinite: false,
			swipe: false,
			responsive: [
				{breakpoint: 1024, settings: {slidesToShow: 1, slidesToScroll: 1, infinite: true}},
				{breakpoint: 600, settings: {slidesToShow: 1, slidesToScroll: 1}},
				{breakpoint: 480, settings: {slidesToShow: 1, slidesToScroll: 1}}
			]
		});

		$(window).resize();
	}, 0)


}

function tickToggleHideStylePopup(res, qType) {
	if (res === null || res === 'null') {
		return 'display:none';
	}
	if (qType === 'trueQ') { // for yes button question
		if (res == 1) {
			return 'display:inline';
		} else {
			return 'display:none';
		}

	} else {
		if (res == 0) {  // for false question
			return 'display:inline'
		} else {
			return 'display:none';
		}
	}
}

$('#ShortlistModal').on('show.bs.modal', function () {
	$(window).resize();
});

function getDateFormatShortlist(date) {
	if (date == null) return moment(new Date()).format('DD/M | hh:mm A');
	return moment(date).format('DD/M | hh:mm A');
}

function DateRangeInitPopup() {

	$('input[name="dateTimesPopup"]').daterangepicker({
		parentEl: '.datetimeBlockPopup',
		singleDatePicker: true,
		drops: 'up',
		opens: 'center',
		timePicker: true,
		minDate: moment().startOf('hour'),
		locale: {
			format: 'DD/M | hh:mm A'
		}
	});

	$('.dateTimePopup').on('apply.daterangepicker', function (ev, picker) {
		let date = picker.startDate.format('DD/M | hh:mm A');
		$(this).closest('.package-grid-shortlist').find('.walkin-date-time-popup').text(date);
		let schoolId = $(this).closest('.package-grid-shortlist').attr('data-school');
		wishToVisitSchoolDateTimeHome(schoolId, picker.startDate.format('DD-MM-YYYY hh:mm A'));
		// $(this).closest('.package-grid-shortlist').find('.datetimeBlockPopup').hide();
		// $(this).closest('.package-grid-shortlist').find('.walkin-date-time-block-popup').show();
	});

	walkinDateEditPopupInit();

}

function walkinDateEditPopupInit() {
	$('.edit-walkin-date-popup').on('click', function (e) {
		$(this).closest('.package-grid-shortlist').find('.datetimeBlockPopup').show();
	});
}

function wishToVisitSchoolDateTimeHome(schoolId, datetime) {
	$.ajax({
		type: 'POST',
		data: {school: schoolId, datetime: datetime},
		url: HOST + '/home/wishlist-new/server/school-visited-date-time.php',
		success: function (data) {
			if (DEBUG) console.log(data);
		}, error: function (data) {
			if (DEBUG) console.log(data);
		}
	})
}

function ToggleReadMore() {
	var showChar = 100;
	var ellipsestext = "...";
	var moretext = "Read more";
	var lesstext = "Read less";
	$('.school-comment-popup').each(function () {
		var content = $(this).html();

		if (content.length > showChar) {

			var c = content.substr(0, showChar);
			var h = content.substr(showChar - 1, content.length - showChar);

			var html = c + '<span class="moreelipses">' + ellipsestext + '</span>&nbsp;<span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

			$(this).html(html);
		}

	});

	$(".morelink").click(function () {
		if ($(this).hasClass("less")) {
			$(this).removeClass("less");
			$(this).html(moretext);
		} else {
			$(this).addClass("less");
			$(this).html(lesstext);
		}
		$(this).parent().prev().toggle();
		$(this).prev().toggle();
		return false;
	});
}
